<template>
	<div class="p-0">
		<ButtonGista class="w-full sm:w-max" @click="toggle">Download Report</ButtonGista>
		<GistaModal 
      :value="isOpen"
			width="2/4"
			@input="toggle">
			<div class="p-6">
				<div class="flex justify-center mb-6">
					<h4 class="text-xl font-semibold">Download Report</h4>
				</div>
				<div class="flex justify-center mb-6 text-center">
					<p>Report will be shown as .xlsx file within the selected date range.</p>
				</div>
				<div class="flex flex-wrap justify-center">
					<div class="flex flex-wrap mb-3.5 md:mb-0 w-full md:w-max">
						<select-dashboard class="flex-auto mr-0 md:mr-3.5 mb-3.5"
							label="Type:"
							:options="optionsType"
							@change="changeType">
							<template #selected>
								<p>{{type.text}}</p>
							</template>
						</select-dashboard>
            <date-picker-custom 
              @input="updateDate($event)"
              mode="date"
              placeholder="Select Date"
              size="large"
							:max-date="new Date()"
							v-model="formDateInitial"
              is-range
              class="flex-auto w-full md:w-72 mr-0 md:mr-3.5 mb-3.5"
              >
            </date-picker-custom>
					</div>
					<ButtonGista 
            type="primary"
            color="success"
            customClass="md:w-44 w-full mb-3.5"
						@click="downloadReport">
						<span v-if="!loading">Download</span>
						<div v-else
							class="w-6 h-6 ease-linear border-4 border-t-4 rounded-full loader border-success"
						></div>
					</ButtonGista>

				</div>
			</div>
		</GistaModal>

	</div>
</template>
<script>
import SelectDashboard from '@/core/components/custom/SelectDashboard.vue'
import DatePickerCustom from '@/core/components/custom/DatePicker.vue'
import { formatDate2 } from '@/core/components/helpers/format.js'
export default {
	name: 'DownloadReport',
	data() {
		return {
			isOpen: false,
			optionsType: [
				{ text: 'RFX', value: 'RFX'}, 
				{text: 'RFP', value: 'RFP'}, 
				{ text: 'RFQ', value: 'RFQ' }, 
				{ text:'Detailed Report', value: 'DetailedReport' }
			],
			type: { text: 'RFX', value: 'RFX' },
      formDate: {
        start: '',
        end: ''
      },
			formDateInitial: {
				start: new Date(new Date().getFullYear(),0,1),
        end: new Date()
			},
			loading: false,
		}
	},
	computed: {
		modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    }
	},
	methods: {
		formatDate2,
		toggle() {
			this.isOpen = !this.isOpen
		},
		updateDate(e) {
			console.log(e, 'date');
			this.formDate = e
		},
		async downloadReport() {
			if (this.loading) return
			const data = {
				reportName: this.type.value,
				start: this.formDate.start || this.formatDate2(this.formDateInitial.start),
				end: this.formDate.end || this.formatDate2(this.formDateInitial.end),
			}
			try {
				this.loading = true
				const response = await this.$store.dispatch('report/getDownloadReport', data)
				if (response?.status < 300 && response?.status >= 200) {
					this.loading = false
				} else {
					this.$store.dispatch('modal/throwError', { response })
				}
			} catch (error) {
				console.log(error);
				this.loading = false
				//show modal error download report
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
			}
		},
		changeType(e) {
			console.log(e, 'type');
			this.type = e
		},
	},
  components: {
    SelectDashboard,
		DatePickerCustom
  }
}

</script>

<style scoped>
.loader {
  border-top-color:rgba(255, 255, 255, 1);
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>