//constant status options for filter in dashboard projects
import { GSIT_01_AWAITING_APPROVAL, UKKP_01_AWAITING_APPROVAL, USER_01_AWAITING_APPROVAL, STAFF_ITP_01_AWAITING_APPROVAL, 
  SPV_ITP_01_AWAITING_APPROVAL, VENDOR_01_AWAITING_PRE_QUALIFICATION_DOCUMENT, VENDOR_02_AWAITING_PROPOSAL_PRICE_DOCUMENT, 
  GSIT_02_AWAITING_DOCUMENT_REVIEW, GSIT_03_AWAITING_SCORING, UKKP_03_AWAITING_SCORING, GSIT_03_AWAITING_SCORING_REVISION, UKKP_03_AWAITING_SCORING_REVISION,
  GSIT_03_AWAITING_SCORING_APPROVAL, UKKP_03_AWAITING_SCORING_APPROVAL, ITP_03_AWAITING_SCORING_APPROVAL,
  COMPLETED, CANCELED, GSIT_01_AWAITING_REVISION, UKKP_01_AWAITING_REVISION, ITP_04_AWAITING_REVIEW, STAFF_ITP_02_AWAITING_QUOTATION } from '@/core/constant/statusProject.js'

export const statusCompletedMap = {
  allStatus: {
    text: 'All Status',
    value: ''
  },
  done: {
    text: 'Done',
    value: COMPLETED
  },
  canceled: {
    text: 'Canceled',
    value: CANCELED
  }
}
export const statusOutstandingMap = {
  allStatus: {
    text: 'All Status',
    value: ''
  },
  waitingForAppprovalGsit: {
    text: 'Waiting for Approval GSIT',
    value: GSIT_01_AWAITING_APPROVAL
  },
  waitingForApprovalUkkp: {
    text: 'Waiting for Approval UKKP',
    value: UKKP_01_AWAITING_APPROVAL
  },
  waitingForApprovalGsitRfq: {
    text: 'Waiting for Approval GSIT',
    value: USER_01_AWAITING_APPROVAL
  },
  waitingForApprovalUkkpRfq: {
    text: 'Waiting for Approval UKKP',
    value: USER_01_AWAITING_APPROVAL
  },
  waitingForItpReview: {
    text: 'ITP Review',
    value: STAFF_ITP_01_AWAITING_APPROVAL
  },
  waitingForItpReviewRfp: {
    text: 'ITP Review RFP',
    value: STAFF_ITP_01_AWAITING_APPROVAL
  },
  waitingForItpReviewRfq: {
    text: 'ITP Review RFQ',
    value: STAFF_ITP_01_AWAITING_APPROVAL
  },
  waitingForApprovalItp: {
    text: 'Waiting for Approval ITP',
    value: SPV_ITP_01_AWAITING_APPROVAL
  },
  praqualificationProcess: {
    text: 'Praqualification Process',
    value: VENDOR_01_AWAITING_PRE_QUALIFICATION_DOCUMENT
  },
  waitingVendorProposal: {
    text: 'Waiting Vendor Proposal',
    value: VENDOR_02_AWAITING_PROPOSAL_PRICE_DOCUMENT
  },
  userReviewProposal: {
    text: 'User Review Proposal',
    value: GSIT_02_AWAITING_DOCUMENT_REVIEW
  },
  scoringProcess: {
    text: 'Scoring Process',
    value:  `${GSIT_03_AWAITING_SCORING},${UKKP_03_AWAITING_SCORING},${GSIT_03_AWAITING_SCORING_REVISION},${UKKP_03_AWAITING_SCORING_REVISION},${GSIT_03_AWAITING_SCORING_APPROVAL},${UKKP_03_AWAITING_SCORING_APPROVAL},${ITP_03_AWAITING_SCORING_APPROVAL}`
  },
  negotiationProcess: {
    text: 'Negotiation Process',
    value: `${ITP_04_AWAITING_REVIEW},${STAFF_ITP_02_AWAITING_QUOTATION}`
  },
  rejected: {
    text: 'Rejected',
    value: `${GSIT_01_AWAITING_REVISION},${UKKP_01_AWAITING_REVISION}`
  },
}

export const filterProjectsObj = {
  type: {
    all: 'All',
    rfp: 'RFP',
    rfq: 'RFQ'
  },
  sort: {
    projectIdNewest: 'Project ID: Newest',
    projectIdOldest: 'Project ID: Oldest',
    dateCreatedNewest: 'Date Created: Newest',
    dateCreatedOldest: 'Date Created: Oldest'
  },
  status: {
    allStatus: 'All Status',
    waitingForAppprovalGsit: 'Waiting for Approval GSIT',
    waitingForApprovalUkkp: 'Waiting for Approval UKKP',
    waitingForItpReview: 'ITP Review',
    waitingForItpReviewRfp: 'ITP Review RFP',
    waitingForItpReviewRfq: 'ITP Review RFQ',
    waitingForApprovalItp: 'Waiting for Approval ITP',
    praqualificationProcess: 'Praqualification Process',
    waitingVendorProposal: 'Waiting Vendor Proposal',
    userReviewProposal: 'User Review Proposal',
    scoringProcess: 'Scoring Process',
    negotiationProcess: 'Negotiation Process',
    rejected: 'Rejected'
  },

}