<template>
  <div class="relative">
    <div
      :class="`flex flex-col justify-center h-10 p-2 rounded-lg bg-white border ${isOptionsExpanded ? 'border-secondary' : 'border-primary'}`"
    >
      <div class="relative text-sm w-full">
        <button
          class="flex items-center justify-between w-full text-lg rounded-md"
          :class="{
            'text-base': size != 'large',
            'h-12': (size = 'large'),
          }"
          @click="isOptionsExpanded = !isOptionsExpanded"
        >
          <div
            class="absolute inset-y-0 left-0 pl-3 mr-6 flex items-center pointer-events-none"
            v-if="iconLeft"
          >
            <span :class="iconLeft"></span>
          </div>
          <div class="mr-6">
            <label
              class="flex items-center text-xs text-gray"
              :class="{ 'text-error': error }"
            >
              {{ label }}
            </label>
            <p
              class="text-base text-gray-dark"
              :class="{
                'pl-6': iconLeft,
              }"
            >
              <slot name="selected" :slot-props="selected">{{
                selected || placeholder
              }}</slot>
            </p>
          </div>
          <svg
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            class="h-4 w-4 transform transition-transform duration-200 ease-in-out"
            :class="isOptionsExpanded ? 'rotate-180' : 'rotate-0'"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </button>
      </div>
    </div>
    <transition
      enter-active-class="transform transition duration-500 ease-custom"
      enter-class="-translate-y-1/2 scale-y-0 opacity-0"
      enter-to-class="translate-y-0 scale-y-100 opacity-100"
      leave-active-class="transform transition duration-300 ease-custom"
      leave-class="translate-y-0 scale-y-100 opacity-100"
      leave-to-class="-translate-y-1/2 scale-y-0 opacity-0"
    >
      <div v-show="isOptionsExpanded" 
        class="absolute left-0 right-0 rounded-md overflow-hidden bg-white px-3 z-30 shadow-primary-sm"
        >
        <div>
          <div v-for="(v,i) in options" :key="i">
            <GistaCheckbox class="text-gray" @change="changeCheckBox($event, i)" :modelValue="modelValue[i]?.value">{{v}}</GistaCheckbox>
          </div>
        </div>
        <div class="w-full mb-3">
          <button-gista size="small" customClass="w-full" :disabled="disabledApply" @click="clickApply">Apply</button-gista>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "large",
    },
    error: {
      type: Boolean,
      default: false,
    },
    label: String,
    iconLeft: String,
    placeholder: {
      type: String,
      default: "Select",
    },
    options: Array,
    value: {
      type: [Object, String],
    },
  },
  name: "SelectDashboard",
  data() {
    return {
      isOptionsExpanded: false,
      selected: typeof this.value === "object" ? this.value.text : this.value,
      modelValue: {},
      modelValueCurrent: [],
      disabledApply: true
    };
  },
  computed: {
    computedValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    setOption(v) {
      this.isOptionsExpanded = false;
      this.computedValue = typeof v === "object" ? v.value : v;
      this.selected = typeof v === "object" ? v.text : v;
      this.$emit("change", v);
      this.$emit("input", this.value);
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isOptionsExpanded = false;
      }
    },
    changeCheckBox(e, i) {
      this.setModelValueCurrent(i)
      let counter = 0
      for (const key in this.modelValue) {
        if (+key === this.modelValueCurrent[0] || +key === this.modelValueCurrent[1]) {
          if (+key === i) {
            this.modelValue[key] = {value: e}
          }
        } else {
          this.modelValue[key] = {value: false}
        }

        if (this.modelValue[key].value) counter++
      }
      if (counter < 2) {
        this.disabledApply = true
      } else {
        this.disabledApply = false
      }
    },
    setModelValueCurrent(i) {
      if (this.modelValueCurrent.length === 2) {
        if (!this.modelValueCurrent?.includes(i)) {
          this.modelValueCurrent.shift()
          this.modelValueCurrent.push(i)
        }
      } else {
        this.modelValueCurrent.push(i)
      }
    },
    clickApply() {
      this.isOptionsExpanded = false
      const sortModelValueCurrent = this.modelValueCurrent.sort()
      this.selected = `${this.options[sortModelValueCurrent[0]]} - ${this.options[sortModelValueCurrent[1]]}`
      this.$emit('click', { startYear: this.options[sortModelValueCurrent[0]], endYear: this.options[sortModelValueCurrent[1]] })
    }
    
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
    this.modelValue = this.options.reduce((a,v,i) => ({ ...a, [i]: { value: false }}), {})
    const lengthOptions = this.options.length
    this.changeCheckBox(true, lengthOptions-2)
    this.changeCheckBox(true, lengthOptions-1)
    this.clickApply()
  },
  destroyed() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style>
.ease-custom {
  transition-timing-function: cubic-bezier(0.61, -0.53, 0.43, 1.43);
}
</style>
