<template>
  <Bar 
    :chart-data="chartData" 
    :chart-options="datalabel? chartOptionsLabeled : chartOptions" 
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"/>
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'
import { Chart,
  BarElement,
  PointElement,
  BarController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle } from 'chart.js'
Chart.register(
  BarElement,
  PointElement,
  BarController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle);

  export default {
    name: 'VueChart',
    props: {
      chartId: {
        type: String,
        default: 'bar-chart'
      },
      datasetIdKey: {
        type: String,
        default: 'label'
      },
      width: {
        type: Number,
        default: 400
      },
      height: {
        type: Number,
        default: 250
      },
      cssClasses: {
        default: '',
        type: String
      },
      styles: {
        type: Object,
        default: () => ({})
      },
      plugins: {
        type: Array,
        default: () => []
      },
      startYear: {
        type: Number,
        default: 2021
      },
      endYear: {
        type: Number,
        default: 2022
      },
      dataApi: {
        type: Array,
        default: () => []
      },
      backgroundColor: {
        type: String,
        default: '#30D5C9'
      },
      backgroundColor2: {
        type: String,
        default: '#797EF6'
      },
      datalabel: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        chartOptions: {
          responsive: true,
          maintainAspectRatio: true,
          lineTension: 1,
          plugins: {
            legend: {
              align: 'end',
              labels: {
                usePointStyle: true,
              },
            },
            datalabels: {
              display: function() {
                return false
              }
            },
          },
          scales: {
            y: {
              ticks: {
                padding: 10,
                font: {
                  size: 10
                },
              },
            },
            x: {
              grid: {
                display: false,
              },
              ticks: {
                font: {
                  size: 10
                },
              },
            },
          },
        },
        chartOptionsLabeled: {
          responsive: true,
          maintainAspectRatio: false,
          lineTension: 1,
          plugins: {
            legend: {
              align: 'end',
              labels: {
                usePointStyle: true,
              },
            },
            datalabels: {
              formatter: (value) => {
                return value;
              },
              anchor: 'end',
              font: {
                size: 10
              },
              align: 'end',
              offset: 1,
              clamp: true
            }
          },
          scales: {
            y: {
              ticks: {
                padding: 10,
                font: {
                  size: 10
                },
              },
            },
            x: {
              grid: {
                display: false,
              },
              ticks: {
                font: {
                  size: 10
                },
              },
            },
          },
        },
      }
    },
    computed: {
      chartStartYear() {
        return this.dataApi?.filter(e => e.year === +this.startYear)
      },
      chartEndYear() {
        return this.dataApi?.filter(e => e.year === +this.endYear)
      },
      chartLabels() {
        return this.chartStartYear.map(e => e.monthMMM_ENG)
      },
      chartData() {
        return {
          labels: this.chartLabels,
          datasets: [
            {
              label: this.startYear,
              backgroundColor: this.backgroundColor,
              data: this.chartStartYear.map(e => e.total)
            },
            {
              label: this.endYear,
              backgroundColor: this.backgroundColor2,
              data: this.chartEndYear.map(e => e.total)
            }
          ],
        }
      }
    },
    components: {
      Bar
    }
  }

</script>